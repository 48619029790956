import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'react-fast-compare'
import { Sheet, LoadBar, Button as IconButton, CardError } from 'components'
import { ButtonBlock, Button } from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useCurrent,
  useAutoTable,
  useForm,
  usePagination,
  useSort,
} from 'contexts'
import { useRequest, useInterval, useEvent } from 'hooks'
import {
  REPOPULATION_ERROR_STATUS,
  REPOPULATION_IDLE_STATUS,
  REPOPULATION_PENDING_STATUS,
} from 'constants/lists'
import { fetchList, fetchMembers, fetchPreview } from 'requests/lists'
import ListMemberTableActions from './ListMemberTableActions'
import tableConfig from './tableConfig'
import styles from './ListMemberTable.module.scss'

const removeEmptyAndInvalidFilters = filterGroup =>
  Object.entries(filterGroup).reduce(
    (cleanedFilters, [filterId, filterData]) => {
      if (
        Object.keys(filterData).filter(
          key => key !== 'id' && (filterData[key] ?? false)
        ).length > 0 &&
        filterData.isValid
      ) {
        cleanedFilters[filterId] = filterData
      }
      return cleanedFilters
    },
    {}
  )

const ListMemberTable = ({ currentList, makeListRequest }) => {
  const { t } = useTranslation()
  const { renderTable } = useAutoTable()
  const { pageSize, page } = usePagination()
  const { sortColumn, sortOrder } = useSort()
  const {
    setField,
    areFieldsValid,
    formData: {
      requiredFilters,
      conditionalFilters,
      excludedFilters,
      hasRefreshRequest,
      requiredFiltersAreValid,
      conditionalFiltersAreValid,
      excludedFiltersAreValid,
    },
  } = useForm()
  const [members, setMembers] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const { currentUser } = useCurrent()
  const isListShared = currentList.user_id !== currentUser.id

  const abortController = useRef()

  const allFiltersAreValid =
    requiredFiltersAreValid !== false &&
    conditionalFiltersAreValid !== false &&
    excludedFiltersAreValid !== false

  const {
    makeRequest: makeMemberRequest,
    isLoading: isMemberLoading,
    errors: memberErrors,
    clearRequest: clearMemberRequest,
  } = useRequest(
    (listId, params, signal) =>
      fetchMembers(
        listId,
        {
          fields: [
            'id',
            'first_name',
            'middle_name',
            'last_name',
            'gender',
            'birth_date',
            { organizations: ['name'] },
            { residential_address: ['zipcode', 'county'] },
            { teams: ['name'] },
          ],
          current_page: params.page,
          per: params.pageSize,
          sort_attr: params.sortColumn,
          sort_dir: params.sortOrder,
        },
        signal
      ),
    {
      onSuccess: ({ people, meta }) => {
        setMembers(people)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const {
    makeRequest: makePreviewRequest,
    isLoading: isPreviewLoading,
    errors: previewErrors,
    clearRequest: clearPreviewRequest,
  } = useRequest(
    (searchParams, params, signal) =>
      fetchPreview(
        {
          search_params: searchParams,
          current_page: params.page,
          per: params.pageSize,
          sort_attr: params.sortColumn,
          sort_dir: params.sortOrder,
          fields: params.fields,
          associations: params.associations,
        },
        signal
      ),
    {
      onSuccess: ({ people, meta }) => {
        setMembers(people)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const memberErrorMsg = Object.values(memberErrors).join(', ')
  const previewErrorMsg = Object.values(previewErrors).join(', ')

  const hasChanged =
    !!requiredFilters &&
    !!conditionalFilters &&
    !!excludedFilters &&
    !isEqual(
      {
        requiredFilters,
        conditionalFilters,
        excludedFilters,
      },
      {
        requiredFilters: currentList.search_params.requiredFilters || {},
        conditionalFilters: currentList.search_params.conditionalFilters || {},
        excludedFilters: currentList.search_params.excludedFilters || {},
      }
    )

  const onFetchMembers = useEvent((params = {}, signal) => {
    clearPreviewRequest()
    return makeMemberRequest(
      currentList.id,
      {
        pageSize,
        page,
        sortColumn,
        sortOrder,
        ...params,
      },
      signal
    )
  })

  const onFetchPreview = useEvent((params = {}, signal) => {
    clearMemberRequest()
    return makePreviewRequest(
      {
        requiredFilters: removeEmptyAndInvalidFilters(requiredFilters),
        conditionalFilters: removeEmptyAndInvalidFilters(conditionalFilters),
        excludedFilters: removeEmptyAndInvalidFilters(excludedFilters),
      },
      {
        ...params,
        pageSize,
        page,
        sortColumn,
        sortOrder,
        fields: [
          'id',
          'first_name',
          'middle_name',
          'last_name',
          'gender',
          'birth_date',
          { residential_address: ['county', 'zipcode'] },
          { organizations: ['name'] },
          { teams: ['name'] },
        ],
        associations: ['residential_address', 'organizations', 'teams'],
      },
      signal
    )
  })

  const withAbortController = (callback, params) => {
    if (abortController.current) {
      abortController.current.abort()
    }
    abortController.current = new AbortController()
    callback(params, abortController.current.signal)
  }

  const fetchAction = useEvent((params = {}) =>
    hasChanged || hasRefreshRequest
      ? withAbortController(onFetchPreview, params)
      : withAbortController(onFetchMembers, params)
  )

  useEffect(() => {
    areFieldsValid && fetchAction()
  }, [
    areFieldsValid,
    hasChanged,
    fetchAction,
    requiredFilters,
    conditionalFilters,
    excludedFilters,
  ])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: repopulateListReq } = useRequest(
    () =>
      fetchList(currentList.id, {
        fields: ['repopulation_status'],
      }),
    {
      onSuccess: ({ list }) => {
        if (list.repopulation_status === REPOPULATION_IDLE_STATUS) {
          makeListRequest()
        }
      },
    }
  )

  const { startInterval, resetInterval } = useInterval(repopulateListReq, 5000)

  useEffect(() => {
    if (currentList.repopulation_status === REPOPULATION_PENDING_STATUS) {
      startInterval()
    } else {
      resetInterval()
    }
  }, [currentList.repopulation_status])

  const handleRefresh = async () => {
    setField(true, 'hasRefreshRequest')
  }

  const isPopulationIdle =
    currentList.repopulation_status === REPOPULATION_IDLE_STATUS
  const isPopulationPending =
    currentList.repopulation_status === REPOPULATION_PENDING_STATUS
  const populationError =
    currentList.repopulation_status === REPOPULATION_ERROR_STATUS &&
    t('There has been an issue populating this list.')

  const isLoading = isMemberLoading || isPreviewLoading || isPopulationPending

  const errorMsg = memberErrorMsg || previewErrorMsg || populationError

  if (totalRecords === 0) {
    return (
      <div className={styles.container}>
        <Sheet className={styles.banner}>
          <LoadBar className={styles.loadbar} show={isLoading} />
          <div className={styles.content}>
            <p>
              {(isPopulationPending || isMemberLoading || isPreviewLoading) &&
                t('This list is repopulating from a recent filter change.')}
              {isPopulationIdle &&
                !isMemberLoading &&
                !isPreviewLoading &&
                t(
                  'No records matched the filter criteria. Please try refreshing the list or changing the criteria.'
                )}
              <CardError hide={!populationError} message={populationError} />
            </p>
            {!isListShared && (
              <ButtonBlock justify="right">
                <Button.Accent
                  onClick={handleRefresh}
                  disabled={!allFiltersAreValid}
                >
                  Refresh
                </Button.Accent>
              </ButtonBlock>
            )}
          </div>
        </Sheet>
      </div>
    )
  }

  return (
    <Sheet className={styles.container}>
      {renderTable({
        data: members,
        totalRecords,
        fetchAction,
        columns: tableConfig.columns,
        loading: isLoading,
        error: errorMsg,
        headerActions: (
          <>
            <IconButton
              icon
              tooltipLabel={t('Refresh')}
              onClick={handleRefresh}
              disabled={isListShared || !allFiltersAreValid}
            >
              refresh
            </IconButton>
            {!hasChanged && (
              <ListMemberTableActions
                currentList={currentList}
                totalRecords={totalRecords}
              />
            )}
          </>
        ),
      })}
    </Sheet>
  )
}

export default ({ currentList, makeListRequest }) => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
    defaultSortColumn="id"
  >
    <ListMemberTable
      currentList={currentList}
      makeListRequest={makeListRequest}
    />
  </AutoTableProvider>
)
