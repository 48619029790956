import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getCurrentPacketVisualQcComplete,
  getCurrentPacket,
  getCurrentPacketLocationState,
  getCurrentPacketShowPors,
} from 'store/qcScans/reducer'
import countiesByState from 'utils/counties.json'
import { useReactRouter, useRequest } from 'hooks'
import { advanceCurrentPacketToReadyForPhoneVerification } from 'store/qcScans/actions'
import { IN_QC, qcPacketFields } from 'constants/qualityControl'
import { redirectToInbox } from 'qualityControl/inbox/utils'
import { startCase, toLower } from 'lodash'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import ApprovalConfirmationPanel from '../ApprovalConfirmationPanel'
import IssuePanel from '../IssuePanel/IssuePanel'
import CountyLookup from '../CountyLookup/CountyLookup'
import ReviewSelectionPanel from '../ReviewSelectionPanel'
import IssueModal from '../IssueModal/IssueModal'
import ProofOfResidence from '../ProofOfResidence/ProofOfResidence'
import styles from './QcReviewPanel.module.scss'

const QcReviewPanel = ({
  currentScan,
  goToNext,
  isVizQcComplete,
  advanceCurrentPacketToReadyForPhoneVerification,
  currentPacket,
  currentPacketLocationState,
  showCountyLookup,
  showPor,
}) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [countyValue, setCountyValue] = useState(
    startCase(toLower(currentScan.county)) || ''
  )
  const { inboxUser: user } = useInbox()
  const { getPackets, getInspectedUser, inspectedUser } = useQualityControl()
  const staffId = user.id
  const { id } = currentScan
  const [canReview, enableReview] = useState(false)
  const [issueModalOpen, setIssueModalOpen] = useState(false)
  const isApproved =
    currentScan.visual_reviews &&
    currentScan.visual_reviews.some(
      visualReview => visualReview.response.response === 'approved'
    )

  const currentReviews = !currentScan.visual_reviews.length
    ? {
        status: 'unreviewed',
        responses: [],
      }
    : {
        status: isApproved ? 'approved' : 'issuesRaised',
        responses: currentScan.visual_reviews,
      }

  const panelMap = {
    unreviewed: ReviewSelectionPanel,
    issuesRaised: IssuePanel,
    approved: ApprovalConfirmationPanel,
  }

  const validCounties = currentPacketLocationState
    ? countiesByState[currentPacketLocationState].concat(['cannot_determine'])
    : []

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestPackets } = useRequest(getPackets, {
    onSuccess: () => redirectToInbox(staffId, history, inspectedUser.id),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestStaffPackets } = useRequest(getInspectedUser, {
    onSuccess: () => redirectToInbox(staffId, history, inspectedUser.id),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: moveToPhoneVerification } = useRequest(
    advanceCurrentPacketToReadyForPhoneVerification,
    {
      onSuccess: () => {
        inspectedUser.id
          ? requestStaffPackets(staffId)
          : requestPackets({
              fields: qcPacketFields,
            })
      },
    }
  )

  useEffect(() => {
    if (isVizQcComplete && currentPacket?.shift.status === IN_QC) {
      moveToPhoneVerification()
    }
  }, [isVizQcComplete, currentPacket?.shift.status])

  useEffect(() => {
    setIssueModalOpen(false)
  }, [id])

  useEffect(() => {
    enableReview(validCounties.includes(countyValue))
  }, [countyValue])

  const CurrentPanel = panelMap[currentReviews.status]

  const formId = currentScan.form?.id

  return (
    <>
      {showPor && !!formId && <ProofOfResidence formId={formId} />}
      {showCountyLookup && (
        <CountyLookup
          enableReview={enableReview}
          countyValue={countyValue}
          setCountyValue={setCountyValue}
          scanId={id}
        />
      )}
      <div className={styles.label}>
        <h3>{t('Visual Quality Control')}</h3>
      </div>
      <CurrentPanel
        canReview={canReview || !showCountyLookup}
        toggleIssuePanel={() => {
          setIssueModalOpen(!issueModalOpen)
        }}
        currentScan={currentScan}
        currentReviews={currentReviews}
        goToNext={goToNext}
      />
      <IssueModal
        isOpen={issueModalOpen}
        closeModal={() => setIssueModalOpen(false)}
      />
    </>
  )
}

export default connect(
  state => ({
    showPor: getCurrentPacketShowPors(state),
    isVizQcComplete: getCurrentPacketVisualQcComplete(state),
    currentPacket: getCurrentPacket(state),
    currentPacketLocationState: getCurrentPacketLocationState(state),
  }),
  {
    advanceCurrentPacketToReadyForPhoneVerification,
  }
)(QcReviewPanel)
