import { CustomFieldsTableConfig } from 'components/CustomFields/CustomFields.types'
import { useCurrent } from 'contexts'
import { BASE_FILTER_TYPES } from './constants'
import { FilterType } from './types'
import { getFilterTypesFromFieldsConfig, toKeyValueMap } from './utils'

export function useFilterTypes() {
  const { tenantFields } = useCurrent() as {
    tenantFields: CustomFieldsTableConfig[]
  }
  const configuredFields =
    tenantFields.find(config => config.table === 'people')?.fields || []

  const customFilters = getFilterTypesFromFieldsConfig(configuredFields)

  const filterTypes = [...BASE_FILTER_TYPES, ...customFilters]

  const filterTypesMap = filterTypes.reduce(toKeyValueMap<FilterType>, {})

  return { filterTypes, filterTypesMap }
}
