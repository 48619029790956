import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from 'contexts'
import { useRequest } from 'hooks'
import {
  fetchCurrentRoles,
  fetchCurrentTenant,
  fetchCurrentUser,
  putCurrentUser,
} from 'requests/current'
import { mergeNotNull } from 'utils/object'
import { US_STATES } from 'utils/constants'
import { FullStory as FS } from '@fullstory/browser'

export const CurrentContext = createContext()

const defaultUser = {
  datatable_settings: {},
  turf: {
    activism_options_config: {
      issues: [],
      skills: [],
      languages: [],
    },
    qc_config: {},
    options: {},
    blocks: [],
  },
  role: {
    permissions: [],
    dashboard_layout: {
      content: {
        widgets: [],
      },
    },
  },
}

const defaultTenant = {
  options: {
    denominations: [],
    responsibilities: [],
    event_roles: [],
    goal_label_options: [],
    organization_membership_types: [],
    event_types: [],
    team_types: [],
    relationship_types: [],
    meeting_types: [],
    genders: [],
    ethnicities: [],
  },
  blocks: [],
  states: [],
}

export const CurrentProvider = ({ children }) => {
  const { isLoggedIn, isImpersonating } = useAuth()

  const [currentUser, setCurrentUser] = useState(defaultUser)
  const [currentTenant, setCurrentTenant] = useState(defaultTenant)
  const [currentRoles, setCurrentRoles] = useState([])

  const {
    makeRequest: userRequest,
    hasErrors: userError,
    isRequestComplete: userComplete,
  } = useRequest(fetchCurrentUser, {
    onSuccess: ({ user }) => {
      setCurrentUser(mergeNotNull(defaultUser, user))
      FS('identify', {
        uid: user.id,
        properties: {
          email: user.email,
        },
      })
    },
  })

  const {
    makeRequest: tenantRequest,
    hasErrors: tenantError,
    isRequestComplete: tenantComplete,
  } = useRequest(() => fetchCurrentTenant(true), {
    onSuccess: ({ 'accounts/tenant': tenant }) => {
      setCurrentTenant(mergeNotNull(defaultTenant, tenant))
    },
  })

  const {
    makeRequest: rolesRequest,
    hasErrors: rolesError,
    isRequestComplete: rolesComplete,
  } = useRequest(fetchCurrentRoles, {
    onSuccess: ({ roles }) => {
      setCurrentRoles(roles)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: refreshCurrentRoles } = useRequest(fetchCurrentRoles, {
    onSuccess: ({ roles }) => {
      setCurrentRoles(roles)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateCurrentUser } = useRequest(putCurrentUser, {
    onSuccess: ({ user }) => setCurrentUser(mergeNotNull(defaultUser, user)),
  })

  const doesCurrentUserHavePermission = permissionWanted => {
    if (
      !permissionWanted ||
      !permissionWanted.resource ||
      !permissionWanted.ability
    ) {
      throw new Error('You must include a valid resource and ability')
    }

    if (currentUser.role.admin) return true

    const permission = currentUser.role.permissions.find(
      a => a.resource === permissionWanted.resource
    )

    if (!permission) return false
    return permission.abilities.includes(permissionWanted.ability)
  }

  const hasErrors = userError || tenantError || rolesError
  const isComplete = userComplete && tenantComplete && rolesComplete

  const isReady = isComplete && !hasErrors

  const requestCurrent = () =>
    Promise.all([userRequest(), tenantRequest(), rolesRequest()])

  useEffect(() => {
    if (isLoggedIn) {
      requestCurrent()
    }
  }, [isLoggedIn, isImpersonating])

  const tenantOptions = currentTenant.options
  const denominationOptions = tenantOptions.denominations.map(denomination => ({
    value: denomination,
    label: denomination,
  }))
  const showDenominations = !!denominationOptions.length
  const responsibilityOptions = tenantOptions.responsibilities.map(
    responsibility => ({
      value: responsibility,
      label: responsibility,
    })
  )

  const eventRoleOptions = tenantOptions.event_roles.map(role => ({
    value: role,
    label: role,
  }))

  const goalTagOptions = tenantOptions.goal_label_options.map(option => ({
    label: option,
    value: option,
  }))
  const membershipTypeOptions = tenantOptions.organization_membership_types.map(
    option => ({
      value: option,
      label: option,
    })
  )
  const teamTypeOptions = tenantOptions.team_types.map(type => ({
    value: type,
    label: type,
  }))
  const relationshipTypeOptions = tenantOptions.relationship_types.map(
    relationshipType => ({
      label: relationshipType,
      value: relationshipType,
    })
  )
  const meetingTypeOptions = tenantOptions.meeting_types.map(type => ({
    value: type,
    label: type,
  }))
  const genderOptions = tenantOptions.genders.map(gender => ({
    value: gender,
    label: gender,
  }))
  const ethnicityOptions = tenantOptions.ethnicities.map(ethnicity => ({
    value: ethnicity,
    label: ethnicity,
  }))
  const locationCategories = tenantOptions.location_categories

  const tenantFields = tenantOptions.custom_field_configs

  const tenantBlocks = currentTenant.blocks
  const qcEnabled = tenantBlocks.some(block => block.name === 'quality_control')
  const vrEnabled = tenantBlocks.some(
    block => block.name === 'voter_registration'
  )
  const petitionsEnabled = tenantBlocks.some(
    block => block.name === 'petitions'
  )

  const currentTenantStateOptions = currentTenant.states.map(state => ({
    value: state,
    label: US_STATES[state],
  }))

  const currentTurfActivismOptionsConfig =
    currentUser.turf.activism_options_config
  const currentTurfActivismIssues = currentTurfActivismOptionsConfig.issues
  const currentTurfActivismSkills = currentTurfActivismOptionsConfig.skills
  const currentTurfActivismLanguages =
    currentTurfActivismOptionsConfig.languages

  const currentTurfOptions = currentUser.turf.options
  const currentTurfLocationTurfable = !!currentTurfOptions.location_turfable

  const currentTurfQcConfig = currentUser.turf.qc_config
  const currentTurfPerformsExternalQC =
    !!currentTurfQcConfig.performs_external_qc

  return (
    <CurrentContext.Provider
      value={{
        currentUser,
        currentTenant,
        currentRoles,
        refreshCurrentRoles,
        updateCurrentUser,
        doesCurrentUserHavePermission,
        tenantOptions,
        denominationOptions,
        showDenominations,
        ethnicityOptions,
        locationCategories,
        responsibilityOptions,
        eventRoleOptions,
        goalTagOptions,
        membershipTypeOptions,
        teamTypeOptions,
        genderOptions,
        relationshipTypeOptions,
        meetingTypeOptions,
        tenantBlocks,
        qcEnabled,
        vrEnabled,
        petitionsEnabled,
        currentTenantStateOptions,
        currentTurfActivismIssues,
        currentTurfActivismSkills,
        currentTurfActivismLanguages,
        currentTurfLocationTurfable,
        currentTurfPerformsExternalQC,
        tenantFields,
      }}
    >
      {isLoggedIn && isReady ? children : null}
    </CurrentContext.Provider>
  )
}

export const useCurrent = () => useContext(CurrentContext)
