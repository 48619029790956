import { CustomFieldConfig } from 'components/CustomFields/CustomFields.types'
import { FilterType } from './types'

export function toKeyValueMap<T>(
  map: Record<string, T>,
  current: T & { id: string }
) {
  map[current.id] = current
  return map
}

const getFieldNameFromId = (id: string) => id.replace('custom_', '')

const formatCustomForApi = (filter: {
  id: string
  value?: string | number | undefined
}) => ({
  rules: [
    {
      column: 'with_custom_field_value',
      param: [getFieldNameFromId(filter.id), filter.value?.toString()],
    },
  ],
})

const formatCustomForUi = (filter: {
  params?: {
    rules: [
      {
        param: [string, string]
      },
    ]
  }
}) => (filter.params ? filter.params.rules[0].param[1] : undefined)

const validateCustomFromUi = (value: any) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return !!value || value === 0
  }
  return false
}

const validateCustomFromApi = (filter: {
  params?: {
    rules: [
      {
        param: [string, string]
      },
    ]
  }
}) => !!filter.params?.rules[0].param[1]

function getFilterTypeForFieldConfig(
  fieldConfig: CustomFieldConfig
): FilterType {
  const baseConfig = {
    id: `custom_${fieldConfig.name}`,
    name: fieldConfig.label ?? fieldConfig.name,
    sectionId: 10,
    validateFromAPI: validateCustomFromApi,
    validateFromUI: validateCustomFromUi,
    formatForAPI: formatCustomForApi,
    formatForUI: formatCustomForUi,
  }

  if (fieldConfig.type === 'integer') {
    return {
      ...baseConfig,
      customType: 'number',
    }
  }

  if (fieldConfig.options?.length) {
    return {
      ...baseConfig,
      customType: 'select',
      fieldOptions: fieldConfig.options.map(option => ({
        label: option,
        value: option,
      })),
    }
  }

  return {
    ...baseConfig,
    customType: 'text',
  }
}

export function getFilterTypesFromFieldsConfig(
  fieldConfigs: CustomFieldConfig[]
): FilterType[] {
  return fieldConfigs.map(getFilterTypeForFieldConfig)
}
